import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Hero from "../components/hero"
import CardIntegration from "../components/cardIntegration"
import IntegrationCategories from "../components/integrationCategories"
import { ArrowScrollToTop } from "../components/arrowScrollToTop"

function IntegrationTag({
  data,
  pageContext: { locale, tag, allIntegrations },
  location,
}) {
  const allIntegrationsDataWithTag = data.prismic.allIntegrationsWithTag
  const integrationsPageData = data.prismic.allIntegrationspages.edges[0].node
  return (
    <>
      <SEO
        title={integrationsPageData.meta_title}
        desc={integrationsPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        location={location}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: integrationsPageData.title,
          subtitle: integrationsPageData.subtitle,
          image: {
            image: integrationsPageData.main_image,
            sharp: integrationsPageData.main_imageSharp,
          },
        }}
      />
      <section id="jumpToIntegration" className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-3">
              <IntegrationCategories
                allIntegrationsData={{ edges: allIntegrations }}
                integrationsPageData={integrationsPageData}
                active={tag}
              />
            </div>
            <div className="column is-9">
              <div className="columns is-multiline">
                {allIntegrationsDataWithTag.edges.map(integration => {
                  return (
                    <div
                      key={integration.node._meta.uid}
                      className="column is-4"
                    >
                      <CardIntegration integration={integration} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ArrowScrollToTop />
    </>
  )
}

export default IntegrationTag

IntegrationTag.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const IntegrationTagQuery = graphql`
  query IntegrationTag($tag: [String!], $locale: String!) {
    prismic {
      allIntegrationsWithTag: allIntegrations(tags: $tag, lang: $locale) {
        edges {
          node {
            _meta {
              uid
              tags
              lang
            }
            title
            main_image
          }
        }
      }

      allIntegrationspages(lang: $locale) {
        edges {
          node {
            _meta {
              lang
              uid
            }
            meta_title
            meta_description
            title
            subtitle
            title_search
            title_categories
            all
            sort
            popular
            featured
            newest
            request_integration_button
            main_image
          }
        }
      }
    }
    site {
      ...SiteInformation
    }
  }
`
